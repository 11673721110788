<template>
    <div>
        <el-checkbox-group class="assess-check" v-model="zrhjCheckList" @change="handleZrhjJcdChanged" :disabled="isDisable || 5-checkNum===0" :max="5-checkNum">
            <el-checkbox v-for="(item2,index2) in dataSource" :key="index2" :label="item2.ZBXMC"></el-checkbox>
        </el-checkbox-group>
    </div>
</template>
<script>
export default {
    name: 'zrhj_checkbox',
    data() {
        return {
            zrhjCheckList: [],
            checkNum: 0,
        }
    },
    props:{
        jclx:{
            type:String,
            default: ''
        },
        dataSource: {
            type: Array,
            default: () => []
        },
        isDisable:{
            type:Boolean,
            default: false
        },
        checkboxObj:{
            type: Object,
            default: () => {},
        }
    },
    watch:{
        dataSource(){
            this.zrhjCheckList = [];
        },
        checkboxObj(){
            let wholeCount = 0;
            let currentCount = 0;
            if(this.checkboxObj.data && this.checkboxObj.data.length>0){
                for(let i = 0; i < this.checkboxObj.data.length; i++){
                    wholeCount+=this.checkboxObj.data[i].JCDXID.length;
                    if(this.checkboxObj.data[i].JCDXID && this.checkboxObj.data[i].JCDXID.length>0){
                        if(this.checkboxObj.data[i].JCLX == this.jclx){
                            currentCount=this.checkboxObj.data[i].JCDXID.length;
                        }
                    }
                }
            }
            this.checkNum = wholeCount - currentCount;
        }
    },
    methods:{
        handleZrhjJcdChanged(val){
            if(val.length >= 5-this.checkNum){
                this.$message({
                    type: 'warning',
                    message: '最多选择5个'
                });
            }
            let newVal = [];
            if(this.zrhjCheckList && this.zrhjCheckList.length>0){
                this.zrhjCheckList.forEach(item=>{
                    if(this.dataSource && this.dataSource.length>0){
                        this.dataSource.forEach(item1=>{
                            if(item1.ZBXMC === item){
                                newVal.push(item1.ID)
                            }
                        })
                    }
                })
            }
            this.$emit('handleZrhjJcdChanged',this.jclx, newVal);
        }
    }
}
</script>
<style lang="scss" scoped>
.assess-check .el-checkbox{
    padding-bottom: 10px;
}
</style>
