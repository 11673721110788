<template>
    <div class="monitor-data-base">
        <div class="select-bar">
            <!-- <label class="select-label">本体病害</label>
            <el-select class="assess-bh-select" v-model="bhValue" placeholder="请选择本体病害" filterable @change="handleBhChanged">
                <el-option v-for="(item,index) in dataSource.bhOption" :key="index" :label="item.JCDXMC" :value="item.JCDXID">
                </el-option>
            </el-select>

            <el-select class="assess-bh-select" v-model="jcdValue" placeholder="本体病害监测点位" filterable @change="handleJcdClick">
                <el-option v-for="(item,index) in jcdList" :key="index" :label="item.JCWZMC" :value="item.ID">
                </el-option>
            </el-select> -->

            <el-date-picker class="pg-date-picker" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" size="mini" @change="handleDateChanged">
            </el-date-picker>
        </div>
        <div class="chart-border">
            <div class="chart-border-top">
                <div class="zrhj-label">自然环境监测点位</div>
                <div class="zrhj-item" v-for="(item,index) in dataSource.zrhjOption" :key="index">
                    <div class="zrhj-item-label">{{item.JCLX}}：</div>

                    <ZrhjSelect class="zrhj-child-select" v-show="item.DWLIST && item.DWLIST.length>0" :jclx="item.JCLX" :dataSource="item.DWLIST" @handleZrhjChanged="handleZrhjChanged"></ZrhjSelect>
                    
                    <ZrhjCheckbox class="zrhj-child-group" :style="{paddingLeft:item.DWLIST && item.DWLIST.length>0?'280px':'110px'}" :jclx="item.JCLX" :dataSource="item.ZBXLIST" :isDisable="item.DWLIST && item.DWLIST.length>0 && (zrhjValueArr && zrhjValueArr.length>0 && !zrhjValueArr[index].JCWZID)" @handleZrhjJcdChanged="handleZrhjJcdChanged" :checkboxObj="checkboxObj"></ZrhjCheckbox>
                </div>
            </div>
            <div class="chart-div">
                <div v-show="noData" class="no-data">暂无相关数据</div>
                <div v-show="!noData" class="chart-content" :id="`chartId-${chartId}`"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import * as handleChartArrayXgsj from './handleChartArrayXgsj.js'
import ZrhjSelect from './zrhj_select'
import ZrhjCheckbox from './zrhj_checkbox'
import * as handleDateFormat from './handleDateFormat.js'
export default {
    name: "related_data",
    data() {
        return {
            bhValue: '',
            jcdList: [],
            jcdValue: '',
            dateValue: null,
            chartId: Math.random() * 100,
            myChart: null,
            zrhjValueArr: [],
            bhChartData:null,
            zrhjChartData: null,
            checkboxObj: {},
            loading: null,
            noData: false
        }
    },
    props: {
        selectData: {
            type: Object,
            default: () => { }
        },
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    components:{
        ZrhjSelect,
        ZrhjCheckbox,
    },
    watch: {
        selectData() {
            this.dateValue = null;
            if (this.selectData) {
                this.handleSelectData();
            }
        },
        // eslint-disable-next-line no-unused-vars
        'dataSource.bhOption': function(val) {
            this.bhValue = '';
            if (this.dataSource.bhOption && this.dataSource.bhOption.length > 0) {
                this.handleDataSource();
            }
        },
        'dataSource.zrhjOption': function(val) {
            if (this.dataSource.zrhjOption && this.dataSource.zrhjOption.length > 0) {
                this.handleZrhjDataSource();
            }
        },
    },
    mounted() {
        
        if (this.selectData) {
            this.handleSelectData();
        }
        if (this.dataSource.bhOption && this.dataSource.bhOption.length > 0) {
            this.handleDataSource();
        }
        if (this.dataSource.zrhjOption && this.dataSource.zrhjOption.length > 0) {
            this.handleZrhjDataSource();
        }

        this.initChart();
        window.addEventListener('resize', () => {
            this.myChart.resize()
        })
    },
    methods: {
        ...mapActions([
            'GetJcsjInfo', //获取监测数据信息
            'GetXgsjInfo', //获取相关数据信息
        ]),
        openFullScreen() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        closeFullScreen() {
            if(this.loading) this.loading.close();
        },
        // 初始化时间范围
        handleSelectData() {
            this.dateValue = null;
            if (this.selectData) {
                let startDate = this.selectData.RWKSSJ || '';
                let endDate = this.selectData.RWJSSJ || '';
                this.dateValue = [new Date(startDate), new Date(endDate)];
            }
        },
        // 初始化病害选择值
        handleDataSource() {
            this.bhValue = '';
            this.jcdList = [];
            this.jcdValue = '';
            this.bhChartData = [];
            // this.zrhjChartData = [];
            // this.zrhjValueArr = [];
            if (this.dataSource.bhOption && this.dataSource.bhOption.length > 0) {
                this.bhValue = this.dataSource.bhOption[0].JCDXID;
                this.jcdList = this.dataSource.bhOption[0].SBDWLIST;
                if (this.jcdList && this.jcdList.length > 0) {
                    // this.jcdValue = this.jcdList[0].ID;
                    if(this.jcdList[0].CGQLIST && this.jcdList[0].CGQLIST.length>0) {
                        this.jcdValue = this.jcdList[0].CGQLIST[0].PCODE;
                    }
                }
                if (this.bhValue && this.dateValue && this.dateValue.length > 0 && this.selectData) {
                    this.getJcsjInfo();
                }
            }
            // if(this.dataSource.zrhjOption && this.dataSource.zrhjOption.length>0){
            //     this.dataSource.zrhjOption.forEach(item=>{
            //         this.zrhjValueArr.push({
            //             JCLX: item.JCLX,
            //             JCWZID: '',
            //             JCDXID: '',
            //         })
            //     })
            // }
        },
        // 初始化相关数据选择值
        handleZrhjDataSource() {
            // this.bhValue = '';
            // this.jcdList = [];
            // this.jcdValue = '';
            // this.bhChartData = [];
            this.zrhjChartData = [];
            this.zrhjValueArr = [];
            // if (this.dataSource.bhOption && this.dataSource.bhOption.length > 0) {
            //     this.bhValue = this.dataSource.bhOption[0].JCDXID;
            //     this.jcdList = this.dataSource.bhOption[0].SBDWLIST;
            //     if (this.jcdList && this.jcdList.length > 0) {
            //         // this.jcdValue = this.jcdList[0].ID;
            //         if(this.jcdList[0].CGQLIST && this.jcdList[0].CGQLIST.length>0) {
            //             this.jcdValue = this.jcdList[0].CGQLIST[0].PCODE;
            //         }
            //     }
            //     if (this.bhValue && this.dateValue && this.dateValue.length > 0 && this.selectData) {
            //         this.getJcsjInfo();
            //     }
            // }
            if(this.dataSource.zrhjOption && this.dataSource.zrhjOption.length>0){
                this.dataSource.zrhjOption.forEach(item=>{
                    this.zrhjValueArr.push({
                        JCLX: item.JCLX,
                        JCWZID: '',
                        JCDXID: '',
                    })
                })
            }
        },
        // 选择时间范围
        handleDateChanged(val) {
            let num = 1;
            if (this.selectData.ZQDW === '0') {
                // 年
                num = Number(this.selectData.ZQ) * 12;
            } else if (this.selectData.ZQDW === '1') {
                // 月
                num = Number(this.selectData.ZQ);
            }
            let recentZqDate = new Date(val[1].getTime() - 24 * 60 * 60 * 1000 * 30 * num);
            if (val[0].getTime() < recentZqDate.getTime()) {
                this.dateValue[0] = recentZqDate;
            }
            this.getJcsjInfo();
            this.getZrhjJcsjInfo();
        },
        // 选择病害
        handleBhChanged(val) {
            let _this = this;
            this.jcdList = [];
            this.jcdValue = '';
            if (this.dataSource.bhOption && this.dataSource.bhOption.length > 0) {
                this.dataSource.bhOption.forEach(item => {
                    if (item.JCDXID === val) {
                        _this.jcdList = item.SBDWLIST;
                        if (_this.jcdList && _this.jcdList.length > 0) {
                            this.jcdValue = _this.jcdList[0].CGQLIST[0].PCODE;
                        }
                    }
                })
                this.getJcsjInfo();
            }
        },
        // 选择监测点
        // eslint-disable-next-line no-unused-vars
        handleJcdClick(val) {
            this.getJcsjInfo();
        },
        // 获取监测数据信息
        async getJcsjInfo() {
            this.bhChartData = [];
            if (this.bhValue !== '' && this.dateValue && this.dateValue.length > 0) {
                if (!this.dateValue[0] || !this.dateValue[1]) return;
                let startDate = handleDateFormat.handleDate(this.dateValue[0]);
                let endDate = handleDateFormat.handleDate(this.dateValue[1]);
                let result = await this.GetJcsjInfo({
                    Jcdxid: this.bhValue, //String 监测对象ID
                    Jcwzid: this.jcdValue, //string 监测位置ID
                    Lx: '3', //String 类型【0：按天取均值；1：按周取均值；2：按月取均值；3：获取原始数据】
                    StartTime: startDate, //String 开始时间
                    EndTime: endDate, //String 结束时间
                })
                
                if (result && result.JCSJLIST && result.JCSJLIST.length > 0) {
                    this.bhChartData = result.JCSJLIST;
                }
            }
            this.handleChartData();
        },
        handleZrhjChanged(jclx,val){
            if(this.zrhjValueArr && this.zrhjValueArr.length>0){
                this.zrhjValueArr.forEach(item => {
                    if(item.JCLX === jclx){
                        item.JCWZID = val;
                        if(item.JCDXID){
                            this.getZrhjJcsjInfo();
                        }
                    }
                })
            }
        },
        handleZrhjJcdChanged(jclx,val){
            if(this.zrhjValueArr && this.zrhjValueArr.length>0){
                this.zrhjValueArr.forEach(item => {
                    if(item.JCLX === jclx){
                        item.JCDXID = val;
                    }
                })
                this.checkboxObj = {
                    date: new Date(),
                    data: this.zrhjValueArr
                };
                this.getZrhjJcsjInfo();
            }
        },
        // 获取相关数据信息
        async getZrhjJcsjInfo() {
            this.zrhjChartData = [];
            let pgxgxList = [];
            if(this.zrhjValueArr && this.zrhjValueArr.length>0){
                this.zrhjValueArr.forEach(item=>{
                    if(item.JCDXID && item.JCDXID.length>0){
                        item.JCDXID.forEach(item1=>{
                            if(item1){
                                pgxgxList.push({
                                    JCWZID: item.JCWZID || '',
                                    JCDXID: item1
                                })
                            }
                        })
                    }
                })
            }

            if (pgxgxList && pgxgxList.length>0 && this.dateValue && this.dateValue.length > 0) {
                let PgxgxList = JSON.stringify(pgxgxList);
                if (!this.dateValue[0] || !this.dateValue[1]) return;
                let startDate = handleDateFormat.handleDate(this.dateValue[0]);
                let endDate = handleDateFormat.handleDate(this.dateValue[1]);
                this.openFullScreen();
                let result = await this.GetXgsjInfo({
                    PgxgxList: PgxgxList, //String 评估相关项列表
                    StartTime: startDate, //String 开始时间
                    EndTime: endDate, //String 结束时间
                })

                if (result && result.length > 0) {
                    result.forEach(item1=>{
                        if(item1 && item1.JCSJLIST && item1.JCSJLIST.length>0){
                            this.zrhjChartData.push(item1);
                        }
                    })
                }
            }
            this.handleChartData();
        },
        handleChartData(){
            let prepareArr = [];
            let legendData = [];
            let dwData = [];
            let fieldName = {
                CHILD: 'JCSJLIST',
                NAME: 'JCX',
                XVALUE: 'CJSJ',
                YVALUE: 'JCZ'
            };

            if(this.bhChartData && this.bhChartData.length>0){
                this.bhChartData.forEach(item1 => {
                    if (item1 && item1.JCSJLIST && item1.JCSJLIST.length > 0) {
                        legendData.push(item1.JCX);
                        dwData.push(item1.JCSJLIST[0].DW);
                        item1.JCSJLIST.forEach(item2 => {
                            prepareArr.push(item2.CJSJ);
                        })
                    }
                })
            }

            if(this.zrhjChartData && this.zrhjChartData.length>0){
                this.zrhjChartData.forEach(item1 => {
                    if (item1 && item1.JCSJLIST && item1.JCSJLIST.length > 0) {
                        if(item1.JCSJLIST[0].JCFXYSD){
                            item1.JCSJLIST.forEach(item12=>{
                                // let legendItem = `${item12.JCFXYSD}${item1.JCDXMC}`
                                legendData.push(item12.JCFXYSD);
                                item1.DW?dwData.push(item1.DW):dwData.push('');
                                item12.JCSJLIST.forEach(item2 => {
                                    prepareArr.push(item2.CJSJ);
                                })
                            })
                        }else{
                            legendData.push(item1.JCDXMC);
                            item1.JCSJLIST[0].DW?dwData.push(item1.JCSJLIST[0].DW):dwData.push('');
                            item1.JCSJLIST.forEach(item2 => {
                                prepareArr.push(item2.CJSJ);
                            })
                        }
                    }
                })
            }

            // let xAxisData = handleChartArrayXgsj.handleXAxisDataFun(prepareArr);
            let xAxisData = null;
            let yAxisData = handleChartArrayXgsj.handleYAxisDataFun(xAxisData, this.bhChartData, fieldName,this.zrhjChartData);
            this.setOption(legendData, dwData, xAxisData, yAxisData.yAxisData, yAxisData.yAxis);
        },
        initChart() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart = this.$echarts.init(document.getElementById(`chartId-${this.chartId}`));
        },
        // 初始化图表
        setOption(legendData, dwData, xAxisData, yAxisData,yAxis) {
            if(this.myChart) this.myChart.clear();
            if(!legendData || legendData.length===0 || !dwData || dwData.length===0 || !yAxis || yAxis.length===0) {
                this.closeFullScreen();
                this.noData = true;
                return;
            }
            this.noData = false;
            // 指定图表的配置项和数据
            var option = {
                tooltip: {
                    trigger: 'axis',
                    // position: function(pt) {
                    //     return [pt[0], '10%'];
                    // },
                    axisPointer: {
                        type: 'cross'
                    },
                    // eslint-disable-next-line no-unused-vars
                    formatter: function(params, ticket, callback) {
                        let tooltipDataArr = []
                        if (params && params.length > 0) {
                            params.forEach(item1 => {
                                // if(item1.value){
                                    tooltipDataArr.push({
                                        color: item1.color,
                                        marker: item1.marker,
                                        seriesName: item1.seriesName,
                                        value: item1.value[1] || '-',
                                        dw: item1.value && dwData[item1.seriesIndex] ? dwData[item1.seriesIndex] : ''
                                    })
                                // }
                            })
                        }
                        let tooltipData = params[0].axisValueLabel;
                        if (tooltipDataArr && tooltipDataArr.length > 0) {
                            tooltipDataArr.forEach(item2 => {
                                tooltipData = tooltipData + '<br/>' + item2.marker + item2.seriesName + '：' + item2.value + item2.dw
                            })
                        }
                        return tooltipData;
                    }
                },
                grid: {
                    top: 80,
                    right: 60 * yAxis.length,
                    bottom: 70,
                    left: 60,
                },
                legend: {
                    type: 'scroll',
                    top: 20,
                    data: legendData
                },
                xAxis: {
                    // type: 'category',
                    // boundaryGap: false,
                    // data: xAxisData,
                    type: 'time',
                },
                yAxis: yAxis,
                // yAxis: {
                //     type: 'value',
                //     boundaryGap: [0, '100%']
                // },
                dataZoom: [{
                    type: 'inside',
                    start: 50,
                    end: 100
                }, {
                    // start: 0,
                    // end: 10,
                    // handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    // handleSize: '80%',
                    // handleStyle: {
                    //     color: '#fff',
                    //     shadowBlur: 3,
                    //     shadowColor: 'rgba(0, 0, 0, 0.6)',
                    //     shadowOffsetX: 2,
                    //     shadowOffsetY: 2
                    // }
                }],
                series: yAxisData,
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
            this.closeFullScreen();
        },
    },
}
</script>
<style lang="scss" scoped>
.monitor-data-base {
    width: 100%;
    .select-bar {
        height: 40px;
        line-height: 40px;
        background: #fff;
        .select-label {
            padding: 0 20px;
            color: #23468c;
        }
        .assess-bh-select {
            width: 200px;
        }
    }
    .chart-border {
        position: relative;
        width: 100%;
        margin-top: 10px;
        // background: #fff;
        
        .chart-border-top{
            padding-bottom: 10px;
            background: #fff;
            .zrhj-label {
                padding: 10px 20px 20px;
                color: #23468c;
            }
            .zrhj-item {
                position: relative;
                min-height: 42px;
                border-bottom: 1px solid #d1d7ca;
                .zrhj-item-label {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 90px;
                    color: #23468c;
                }
                .zrhj-child-select {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 110px;
                    width: 140px;
                }
                .zrhj-child-group {
                    width: 100%;
                    padding: 10px 20px 0 280px;
                }
            }
            .zrhj-item:last-child{
                border-bottom: none;
            }
        }
        .chart-div {
            position: relative;
            width: 100%;
            height: 340px;
            .chart-content {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }
            .no-data {
                width: 100%;
                height: 100%;
                line-height: 340px;
                text-align: center;
            }
        }
    }
}
</style>
