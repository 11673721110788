<template>
    <div class="assess-step2">
        <div class="step2-top" v-if="pgdxType!=='本体病害' && carouselDatas && carouselDatas.length > 0">
            <div class="step2-header">
                <div class="step2-title" v-if="isEdit">请对该{{pgdxType}}下{{pgdxChildType}}进行评估</div>
                <div class="step2-title" v-else>该{{pgdxType}}下{{pgdxChildType}}的评估结果</div>
            </div>
            <div class="top-content">
                <el-carousel class="assess-step2" indicator-position="outside" :autoplay="false">
                    <el-carousel-item v-for="(item,index) in carouselDatas" :key="index">
                        <el-table class="top-table top-table1" stripe :data="item.data1">
                            <el-table-column :prop="pgdxChildProp" :label="pgdxChildType" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="ZT" label="现状评估">
                                <template slot-scope="scope">
                                    <el-select class="assess-step2-input" size="mini" filterable v-model="scope.row.ZT" placeholder="请选择评估现状" :disabled="!isEdit">
                                        <el-option v-for="item in pgChildZtList" :key="item.Code" :label="item.Name" :value="item.Code">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column prop="PGJG" label="评估说明">
                                <template slot-scope="scope">
                                    <el-input class="assess-step2-input" size="mini" v-model="scope.row.PGJG" placeholder="请输入评估说明" :disabled="!isEdit" :maxlength="500"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table class="top-table top-table2" v-if="item.data2 && item.data2.length>0" stripe :data="item.data2">
                            <el-table-column :prop="pgdxChildProp" :label="pgdxChildType" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="ZT" label="现状评估">
                                <template slot-scope="scope">
                                    <el-select class="assess-step2-input" size="mini" filterable v-model="scope.row.ZT" placeholder="请选择评估现状" :disabled="!isEdit">
                                        <el-option v-for="item in pgChildZtList" :key="item.Code" :label="item.Name" :value="item.Code">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column prop="PGJG" label="评估说明">
                                <template slot-scope="scope">
                                    <el-input class="assess-step2-input" size="mini" v-model="scope.row.PGJG" placeholder="请输入评估说明" :disabled="!isEdit" :maxlength="50"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="step2-bottom">
            <div class="step2-header">
                <div class="step2-title" v-if="isEdit">请对该{{pgdxType}}进行评估</div>
                <div class="step2-title" v-else>该{{pgdxType}}的评估结果</div>
            </div>
            <div class="bottom-content">
                <el-form class="bottom-form" size="mini" ref="form" :model="formData" label-width="80px">
                    <el-form-item class="must-fill" label="评估现状">
                        <el-select class="assess-step2-input" style="width: 100%;" filterable v-model="formData.ZT" placeholder="请选择评估现状" :disabled="!isEdit">
                            <el-option v-for="item in pgZtList" :key="item.Code" :label="item.Name" :value="item.Code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评估说明">
                        <el-input class="assess-step2-input" type="textarea" :rows="2" v-model="formData.PGJG" :disabled="!isEdit" :maxlength="50"></el-input>
                    </el-form-item>
                    <div class="zjpg-item" v-show="Number(selectData.PGFS)===1">
                        <span class="content-p2">评估报告</span>
                        <div class="upload-content" v-if="isEdit">
                            <ExpertUpload @successUpload="successUpload" :fileList="fileList"></ExpertUpload>
                        </div>
                        <div class="assess-p" v-else>
                            <div v-for="(item1,index1) in formData.PGBGLIST" :key="index1">
                                <a class="assess-a" :href="item1.LJ" :download="item1.MC" target="_blank">{{item1.MC}}</a>
                            </div>
                        </div>
                    </div>
                </el-form>
                <el-button class="bottom-btn" type="primary" v-if="isEdit && allowSubmitClick" @click="onSubmit">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import ExpertUpload from "@views/expert_consultation/components/expert-upload"
export default {
    name: "assess_step2",
    data() {
        return {
            formData: {
                PGRWID: '', //评估任务ID
                PGDXID: '', //评估对象ID
                PGFS: '', //评估方式
                PGJG: '', //评估结果
                ZT: '', //状态（现状评估）
                CJRID: '', //采集人ID
            },
            pgChildZtList: [],
            pgZtList: [],
            pgdxType: '', //监测对象类型
            pgdxChildType: '',
            pgdxChildProp: '',
            isEdit: true,
            pages: 0,
            pgjgmxInfo: [],
            carouselDatas: [],
            fjIds: '',
            fileList: [],
            allowSubmitClick: true,
        };
    },
    props: {
        selectData: {
            type: Object,
            default: () => { }
        }
    },
    components:{
        ExpertUpload
    },
    watch: {
        selectData() {
            this.handleSelectData();
        },
    },
    mounted() {
        // eslint-disable-next-line no-undef
        $(document).ready(function() {
            let tmp = document.getElementsByClassName("el-textarea__inner");
            if (tmp && tmp.length > 0 && tmp[0].className) {
                tmp.forEach(item => {
                    item.className = "el-textarea__inner scrollbar";
                })
            }
        });

        this.handleSelectData();
    },
    methods: {
        ...mapActions([
            'GetEnumItem', //获取枚举值
            'GetJcdxListByRwid', //根据任务id获取监测对象列表
            'AddPgjgAndPgjgmxInfo', //保存评估结果与评估明细信息
            'GetPgjgmxInfo', //获取评估结果信息明细
        ]),
        handleSelectData() {
            this.allowSubmitClick = true;
            this.pgdxType = '';
            this.pgdxChildType = '';
            this.pgdxChildProp = '';
            this.pgZtList = [];
            this.pgChildZtList = [];
            this.formData = {};
            this.pgjgmxInfo = [];
            this.pages = 0;
            this.carouselDatas = [];
            this.fileList = [];

            let pgEnumid = '';
            let pgChildEnumid = '';
            let ycysEnumid = '0b9b784f-7ad3-11ea-bd8d-000c2977b7fd'; // 遗产要素评估
            let bhEnumid = '0b9b7798-7ad3-11ea-bd8d-000c2977b7fd'; // 病害评估
            let zdqyEnumid = '0b9b7b41-7ad3-11ea-bd8d-000c2977b7fd'; // 重点区域评估
            let ycdEnumid = '0b9b7c11-7ad3-11ea-bd8d-000c2977b7fd'; // 遗产地评估
            if (this.selectData) {
                if (this.selectData.PGDXLX) {
                    switch (this.selectData.PGDXLX) {
                        case '0':
                            this.pgdxType = '遗产要素';
                            this.pgdxChildType = '本体病害';
                            this.pgdxChildProp = 'JCDXMC';
                            pgEnumid = ycysEnumid;
                            pgChildEnumid = bhEnumid;
                            break;
                        case '1':
                            this.pgdxType = '本体病害';
                            pgEnumid = bhEnumid;
                            break;
                        case '2':
                            this.pgdxType = '重点区域';
                            this.pgdxChildType = '本体病害';
                            this.pgdxChildProp = 'JCDXMC';
                            pgEnumid = zdqyEnumid;
                            pgChildEnumid = bhEnumid;
                            break;
                        case '3':
                            this.pgdxType = '遗产地';
                            this.pgdxChildType = '遗产要素';
                            this.pgdxChildProp = 'YCYSMC';
                            pgEnumid = ycdEnumid;
                            pgChildEnumid = ycysEnumid;
                            break;
                        case '4':
                            this.pgdxType = '遗产组成';
                            this.pgdxChildType = '本体病害';
                            this.pgdxChildProp = 'JCDXMC';
                            pgEnumid = zdqyEnumid;
                            pgChildEnumid = bhEnumid;
                            break;
                    }

                    this.getEnumItem(pgEnumid, pgChildEnumid);
                }

                if (this.selectData.pgZt === 'undone') {
                    this.isEdit = true;
                    if (this.selectData.ID && this.selectData.PGDXLX !== '1') {
                        this.getJcdxListByRwid();
                    }
                } else {
                    this.isEdit = false;
                    if (this.selectData.PGRWID) {
                        this.getPgjgmxInfo();
                    }
                }
            }
        },
        async getEnumItem(pgEnumid, pgChildEnumid) {
            if(pgEnumid) {
                let pgZtRes = await this.GetEnumItem({
                    Enumid: pgEnumid
                })
                if (pgZtRes && pgZtRes.length > 0) {
                    this.pgZtList = pgZtRes;
                }
            }
            
            if(pgChildEnumid) {
                let pgChildZtRes = await this.GetEnumItem({
                    Enumid: pgChildEnumid,
                });
                if (pgChildZtRes && pgChildZtRes.length > 0) {
                    this.pgChildZtList = pgChildZtRes;
                }
            }
            
        },
        async getJcdxListByRwid() {
            let result = await this.GetJcdxListByRwid({
                Rwid: this.selectData.ID, //String 内存放任务ID
                pageIndex: 1,
                pageSize: 99999,
            });
            let total = result && result.COUNT ? result.COUNT : 0;
            this.pages = Math.ceil(total / 10);
            if (result && result.RESULT && result.RESULT.length > 0) {
                this.pgjgmxInfo = result.RESULT;
                this.handleJcdxList();
            }
        },
        async getPgjgmxInfo() {
            let result = await this.GetPgjgmxInfo({
                Pgrwid: this.selectData.PGRWID, //String 评估任务ID
            });
            let total = result && result.PGJGMXINFOLIST && result.PGJGMXINFOLIST.length ? result.PGJGMXINFOLIST.length : 0;
            this.pages = Math.ceil(total / 10);
            if (result) {
                if (result.PGJGINFO) {
                    this.formData = result.PGJGINFO;
                }
                if (result.PGJGMXINFOLIST && result.PGJGMXINFOLIST.length > 0) {
                    this.pgjgmxInfo = result.PGJGMXINFOLIST;
                    this.handleJcdxList();
                }
            }
        },
        handleJcdxList() {
            for (let i = 0; i < this.pages; i++) {
                this.carouselDatas.push({
                    data1: [],
                    data2: [],
                })
                for (let j = 0; j < this.pgjgmxInfo.length; j++) {
                    let key = this.pgjgmxInfo[j];
                    this.$set(this.pgjgmxInfo[j], key.PGFS, '');
                    this.$set(this.pgjgmxInfo[j], key.PGJG, '');
                    this.$set(this.pgjgmxInfo[j], key.ZT, '');
                    if (j < 10 * (i + 1) - 5 && j >= 10 * i) {
                        this.carouselDatas[i].data1.push(this.pgjgmxInfo[j]);
                    } else if (j < 10 * (i + 1) && j >= 10 * (i + 1) - 5) {
                        this.carouselDatas[i].data2.push(this.pgjgmxInfo[j]);
                    }
                }
            }
        },
        successUpload(val){
            this.fjIds = val;
        },
        onSubmit() {
            this.formData.PGRWID = this.selectData.ID;
            this.formData.PGDXID = this.selectData.PGDXID;
            this.formData.CJRID = localStorage.userId;
            this.formData.PGFS = this.selectData.PGFS;
            let isCanSave = true;
            if(!this.formData.ZT || this.formData.ZT === null || this.formData.ZT === ''){
                isCanSave = false;
                this.$message({
                    type: 'warning',
                    message: `请选择${this.pgdxType}评估现状`
                });
                return;
            }else{
                if (this.pgZtList && this.pgZtList.length > 0) {
                    this.pgZtList.forEach(item => {
                        if (item.Code === this.formData.ZT) {
                            this.formData.ZTMC = item.Name;
                        }
                    })
                }
            }
            
            let infoList = [];
            if(this.selectData.PGDXLX !== '1'){
                if (this.carouselDatas && this.carouselDatas.length > 0) {
                    this.carouselDatas.forEach(itemCar => {
                        infoList = infoList.concat(itemCar.data1, itemCar.data2);
                    })
                }
                if (infoList && infoList.length > 0) {
                    for(let i = 0;i<infoList.length;i++){
                        if(!infoList[i].ZT || infoList[i].ZT===null || infoList[i].ZT===''){
                            isCanSave = false;
                            let name = infoList[i].JCDXMC?infoList[i].JCDXMC:infoList[i].YCYSMC;
                            this.$message({
                                type: 'warning',
                                message: `请选择${name}评估现状`
                            });
                            break;
                        }else{
                            infoList[i].PGFS = this.selectData.PGFS;
                            if (this.pgChildZtList && this.pgChildZtList.length > 0 && infoList[i].ZT) {
                                this.pgChildZtList.forEach(item2 => {
                                    if (item2.Code === infoList[i].ZT) {
                                        infoList[i].ZTMC = item2.Name;
                                    }
                                })
                            }
                        }
                    }
                }
            }

            if(isCanSave){
                this.addPgjgAndPgjgmxInfo(infoList);
            }
        },
        async addPgjgAndPgjgmxInfo(infoList){
            this.allowSubmitClick = false;
            let result = await this.AddPgjgAndPgjgmxInfo({
                PgjgInfo: this.formData, //String 内存放评估记录信息
                PgjgmxInfoList: infoList, //String 内存放评估记录明细信息
                fileIds: this.fjIds, //String 附件ID（逗号分隔）
            });
            if (result.ISSUCCESS) {
                this.$message({
                    message: result.RESULTDESCRIPTION || '提交成功',
                    type: 'success'
                });
                location.reload();
            } else {
                this.$message({
                    message: result.RESULTDESCRIPTION || '提交失败',
                    type: 'error'
                });
                this.allowSubmitClick = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.assess-step2 {
    position: relative;
    width: 100%;
    height: 100%;

    .step2-top,
    .step2-bottom {
        width: 100%;
        padding: 10px 0 0 0;
    }
    .step2-header {
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        border-bottom: 1px solid #85b2cc;
        .step2-title {
            color: #aaa;
        }
    }
    .top-content {
        position: relative;
        width: 100%;
        padding: 10px 20px 0px;
        .top-table {
            width: calc(50% - 10px);
        }
        .top-table2 {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
    .bottom-content {
        position: relative;
        width: 100%;
        padding: 20px;
        .bottom-form {
            width: calc(100% - 140px);
            .zjpg-item {
                display: flex;
                flex-flow: row nowrap;
                margin-top: 10px;
                .content-p2 {
                    width: 80px;
                    padding: 0 12px 0 0;
                    text-align: right;
                    font-size: 14px;
                    color: #23468c;
                }
                .upload-content{
                    flex-grow: 1;
                }
                .assess-p {
                    width: calc(100% - 100px);
                    line-height: 24px;
                    .assess-a {
                        color: #00f;
                        cursor: pointer;
                    }
                }
            }
        }
        .bottom-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 125px;
            height: 95px;
            border-radius: 10px;
            border-color: #2a3987;
            background: #2a3987;
            font-size: 16px;
            font-weight: bold;
        }
    }
}
</style>

