// 随机生成时间
const Color = () => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return 'rgba(' + r + ',' + g + ',' + b + ',1)';
}

// const colorArr = ['#5a91fb', '#cdddfd', '#5ad9a7', '#cdf3e5', '#5c7091', '#cdd5df', '#f7bd16', '#fdebb9', '#e86452', '#f7d1cb', '#6ec9eb', '#d4f0fa', '#955eb9', '#ddcfe9', '#ff9944', '#ffdfc7', '#1e9593', '#bddfdf', '#ff97c3', '#ffe1ee'];
const colorArr = ['#FF0000', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#FF00FF', '#FF7F00', '#7FFF00', '#00FF7F', '#007FFF', '#7F00FF', '#FF007F'];

// 处理x轴数据：数组去重、排序
// 传入合并后的数据,如：[1,2,4,2,6,5,4]
const handleXAxisDataFun = (data) => {
    if (!data || data.length === 0) return;

    // // 数组合并
    // let concatData = [];
    // data.map(function(value, index, array) {
    //     concatData = concatData.concat(value);
    // })

    // 数组去重
    let setData = Array.from(new Set(data));

    // 数组排序
    let sortData = setData.sort();

    return sortData;
}

// 处理series数据
const handleYAxisDataFun = (xAxisData, data, fieldName) => {
    let count = 0;
    let newData = [];
    if (data && data.length > 0) {
        data.forEach(item1 => {
            let tmpArr = [];
            if (item1 && item1[fieldName.CHILD] && item1[fieldName.CHILD].length > 0) {
                item1[fieldName.CHILD].forEach(item2 => {
                    if (item2[fieldName.XVALUE]) {
                        let date = item2[fieldName.XVALUE].substring(0, 19);
                        date = date.replace(/-/g, '/'); //必须把日期'-'转为'/'
                        let timestamp = new Date(date).getTime();
                        let item2Value = [timestamp, item2[fieldName.YVALUE]];
                        tmpArr.push(item2Value);
                    }
                })
            }

            // if (xAxisData && xAxisData.length > 0) {
            //     xAxisData.forEach(itemx => {
            //         let item2Value = null;
            //         if (item1 && item1[fieldName.CHILD] && item1[fieldName.CHILD].length > 0) {
            //             item1[fieldName.CHILD].forEach(item2 => {
            //                 if (item2[fieldName.XVALUE] === itemx) {
            //                     item2Value = item2[fieldName.YVALUE];
            //                 }
            //             })
            //         }
            //         tmpArr.push(item2Value)
            //     })
            // }
            let color = colorArr[count] || Color();
            newData.push({
                name: item1[fieldName.NAME],
                type: 'line',
                smooth: true,
                connectNulls: true,
                symbol: 'circle',
                symbolSize: 10,
                sampling: 'average',
                itemStyle: {
                    color: color
                },
                markPoint: {
                    data: [
                        { type: 'max', name: '最大值' },
                        { type: 'min', name: '最小值' }
                    ]
                },
                // areaStyle: {
                //     color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                //         offset: 0,
                //         color: color
                //     }, {
                //         offset: 1,
                //         color: 'rgb(255, 255, 255)'
                //     }])
                // },
                data: tmpArr
            });
            count++;
        });
    }
    return newData;
}
export {
    handleXAxisDataFun,
    handleYAxisDataFun,
}