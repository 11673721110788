// 格式化：yy-MM-dd
const handleDate = (oraDate) => {
    let newDate = '';
    if (oraDate) {
        let sFullY = oraDate.getFullYear();
        let sM = oraDate.getMonth() + 1;
        let sFullM = sM < 10 ? `0${sM}` : sM;
        let sD = oraDate.getDate();
        let sFullD = sD < 10 ? `0${sD}` : sD;
        newDate = `${sFullY}-${sFullM}-${sFullD}`;
    }
    return newDate;
}
export {
    handleDate,
}