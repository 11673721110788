<template>
    <div>
        <el-select v-model="zrhjJcdValue" placeholder="请选择监测点" filterable @change="handleZrhjChanged">
            <el-option v-for="(item3,index3) in dataSource" :key="index3" :label="item3.DWMC" :value="item3.ID">
            </el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: 'zrhj_select',
    data() {
        return {
            zrhjJcdValue: '',
        }
    },
    props:{
        jclx:{
            type:String,
            default: ''
        },
        dataSource: {
            type: Array,
            default: () => []
        }
    },
    watch:{
        dataSource(){
            this.zrhjJcdValue = '';
        }
    },
    methods:{
        handleZrhjChanged(val){
            this.$emit('handleZrhjChanged',this.jclx,val);
        }
    }
}
</script>
