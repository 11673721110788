<template>
  <div class="undone-detail-container">
    <div class="no-data" v-if="!selectData">暂无数据</div>
    <div class="has-data" v-else>
      <div
        class="name-title"
        :title="selectData.RWMC || selectData.PGBGMC || ''"
      >
        {{ selectData.RWMC || selectData.PGBGMC || "" }}
      </div>
      <div class="detail-bottom scrollbar">
        <div class="title">步骤一 数据支持</div>
        <div class="assess-menu-border">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo assess-menu"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">监测数据</el-menu-item>
            <el-menu-item index="2">评估数据</el-menu-item>
            <el-menu-item index="3">相关数据</el-menu-item>
          </el-menu>
        </div>
        <div class="detail-content">
          <MonitorData
            v-if="currentIndex === '1'"
            :selectData="selectData"
            :dataSource="monitorData"
          ></MonitorData>
          <AssessData
            v-if="currentIndex === '2'"
            :selectData="selectData"
            :dataSource="AssessData"
          ></AssessData>
          <RelatedData
            v-if="currentIndex === '3'"
            :selectData="selectData"
            :dataSource="relatedData"
          ></RelatedData>
        </div>
        <div class="title title2">步骤二 分析评估</div>
        <div class="detail-content detail-content2">
          <AssessStep2 :selectData="selectData"></AssessStep2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectData } from "@store/modules/mutation-type.js";
import { mapActions, mapGetters } from "vuex";
import MonitorData from "./components/monitor_data";
import AssessData from "./components/assess_data";
import RelatedData from "./components/related_data";
import AssessStep2 from "./components/assess_step2";
export default {
  name: "undone-detail",
  data() {
    return {
      activeIndex: "1",
      currentIndex: "1",
      monitorData: {
        bhOption: [],
      },
      AssessData: {
        pgOption: [],
      },
      relatedData: {
        bhOption: [],
        zrhjOption: [],
      },
      loading: null,
    };
  },
  components: {
    MonitorData,
    AssessData,
    RelatedData,
    AssessStep2,
  },
  computed: {
    ...mapGetters({
      selectData,
    }),
  },
  watch: {
    selectData() {
      this.getJcdxListByPgrw();
      this.getXgxdxListByPgrw();
      this.getPgsjList();
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    $(document).ready(function () {
      let tmp = document.getElementsByClassName("el-textarea__inner");
      if (tmp && tmp.length > 0 && tmp[0].className) {
        tmp.forEach((item) => {
          item.className = "el-textarea__inner scrollbar";
        });
      }
    });

    this.getJcdxListByPgrw();
    this.getXgxdxListByPgrw();
    this.getPgsjList();
  },
  methods: {
    ...mapActions([
      "GetJcdxListByPgrw", //获取各项来源监测对象列表
      "GetXgxdxListByPgrw", //获取各项来源相关数据列表
      "GetPgsjList", //获取评估数据列表
    ]),
    openFullScreen() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    closeFullScreen() {
      if (this.loading) this.loading.close();
    },
    // eslint-disable-next-line no-unused-vars
    handleSelect(key, keyPath) {
      this.currentIndex = key;
    },
    async getJcdxListByPgrw() {
      this.monitorData.bhOption = [];
      this.relatedData.bhOption = [];
      if (!this.selectData) return;
      let pgrwid = "";
      if (this.selectData.pgZt === "undone") {
        pgrwid = this.selectData.ID;
      } else {
        pgrwid = this.selectData.PGRWID;
      }
      if (!pgrwid) return;
      this.openFullScreen();
      let result = await this.GetJcdxListByPgrw({
        Pgrwid: pgrwid,
      });
      this.monitorData.bhOption = result ? result : [];
      this.relatedData.bhOption = result ? result : [];
      // this.relatedData.zrhjOption = result && result.XGDXLIST ? result.XGDXLIST : [];
      this.closeFullScreen();
    },
    async getXgxdxListByPgrw() {
      this.relatedData.zrhjOption = [];
      if (!this.selectData) return;
      let pgrwid = "";
      if (this.selectData.pgZt === "undone") {
        pgrwid = this.selectData.ID;
      } else {
        pgrwid = this.selectData.PGRWID;
      }
      if (!pgrwid) return;
      let result = await this.GetXgxdxListByPgrw({
        Pgrwid: pgrwid,
      });
      // this.monitorData.bhOption = result && result.JCDXLIST ? result.JCDXLIST : [];
      // this.relatedData.bhOption = result && result.JCDXLIST ? result.JCDXLIST : [];
      this.relatedData.zrhjOption = result ? result : [];
    },
    async getPgsjList() {
      if (!this.selectData) return;
      let Pgdxid = "";
      Pgdxid = this.selectData.PGDXID;
      if (!Pgdxid) return;
      let result = await this.GetPgsjList({
        Pgdxid: Pgdxid, //string 评估对象ID
      });
      result.IsChild = "0";
      if (this.selectData.PGDXLX === "1") {
        this.AssessData.pgOption = {
          IsChild: result.IsChild,
          JCDXID: result.JCDXID,
          MC: result.MC,
          YCYSBM: "",
        };
      } else {
        if (result.JCDXLIST && result.JCDXLIST.length > 0) {
          result.JCDXLIST.forEach((item) => {
            item.IsChild = "1";
          });
          this.AssessData.pgOption = result;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.undone-detail-container {
  width: 100%;
  height: 100%;
  // border-left: 2px solid #2a3987;
  .no-data {
    text-align: center;
    padding-top: 25%;
    font-size: 20px;
  }
  .has-data {
    width: 100%;
    height: 100%;
  }
  .name-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background: #182987;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail-bottom {
    width: 100%;
    height: calc(100% - 40px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .detail-content {
    width: 100%;
    margin: 10px 0;
  }
  .detail-content2 {
    background: #fff;
  }

  .title {
    padding: 20px 0 0 20px;
    font-size: 16px;
    color: #2a3987;
    font-weight: bold;
  }
  .title2 {
    background: #fff;
    padding-bottom: 10px;
    margin-bottom: -10px;
  }
  .assess-menu-border {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    padding: 0 20px;
    background: #fff;
    .assess-menu.el-menu {
      background: #fff;
    }
    .assess-menu.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .assess-menu.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .assess-menu.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background: #fff;
    }
  }
}
</style>

