<template>
    <div class="monitor-data-base">
        <div class="select-bar">
            <label class="select-label">评估对象</label>
            <el-cascader class="assess-bh-select" :options="pgdxOptions" v-model="pgdxValue" :props="pgdxProps" filterable :show-all-levels="false" @change="handlePgdxChanged" :key="isResouceShow"></el-cascader>

            <el-date-picker class="pg-date-picker" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" size="mini" @change="handleDateChanged">
            </el-date-picker>
        </div>
        <div class="content-border" v-if="timeAxisList && timeAxisList.length>0">
            <div class="time-axis">
                <div class="time-item-line"></div>
                <div class="time-item" v-for="(item,index) in timeAxisList" :key="index">
                    <div class="time-item-icon" :style="{width:currentAxisID===item.PGDXID + item.CJSJ?'34px':'20px',height:currentAxisID===item.PGDXID + item.CJSJ?'34px':'20px',margin:currentAxisID===item.PGDXID + item.CJSJ?'-7px 10px 0 -7px':'0 10px 0 0'}" @click="handleTimeAxisClick(item)">
                        <div class="time-item-icon-in" :style="{background:item.ZT===''?'#ccc':colorArr[Number(item.ZT)],width:currentAxisID===item.PGDXID + item.CJSJ?'26px':'14px',height:currentAxisID===item.PGDXID + item.CJSJ?'26px':'14px'}"></div>
                    </div>
                    <div class="time-label" :style="{color:currentAxisID===item.PGDXID + item.CJSJ?'#2a3987':'#aaaaaa'}" :title="item.CJSJ" @click="handleTimeAxisClick(item)">{{item.CJSJ?item.CJSJ.substr(0,10):''}}</div>
                </div>
            </div>
            <div class="arrow-top" v-if="axisPages>1" @click="handleArrowTopClick">
                <i class="el-icon-arrow-up"></i>
            </div>
            <div class="arrow-bottom" v-if="axisPages>1" @click="handleArrowBottomClick">
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="content">
                <div class="step2-header">
                    <div class="step2-title">{{currentItem.PGDXMC}}现状：{{currentItem.ZTMC}}</div>
                    <div class="step2-title-right">{{currentItem.PGFSMC}}</div>
                </div>
                <div class="top-content">
                    <el-carousel class="assess-step1" indicator-position="outside" :autoplay="false">
                        <el-carousel-item v-for="(item,index) in carouselDatas" :key="index">
                            <div class="carousel-content">
                                <div class="carousel-item">
                                    <div class="carousel-item1" v-for="(item1,index1) in item.data1" :key="index1">
                                        <div class="carousel-item1-mc" :title="item1.JCDXMC">{{item1.JCDXMC}}</div>
                                        <div class="carousel-item1-zt" :style="{color:item1.ZT==''?'':colorArr[Number(item1.ZT)]}">{{item1.ZTMC}}</div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="carousel-item1" v-for="(item1,index1) in item.data2" :key="index1">
                                        <div class="carousel-item1-mc" :title="item1.JCDXMC">{{item1.JCDXMC}}</div>
                                        <div class="carousel-item1-zt" :style="{color:item1.ZT==''?'':colorArr[Number(item1.ZT)]}">{{item1.ZTMC}}</div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="carousel-item1" v-for="(item1,index1) in item.data3" :key="index1">
                                        <div class="carousel-item1-mc" :title="item1.JCDXMC">{{item1.JCDXMC}}</div>
                                        <div class="carousel-item1-zt" :style="{color:item1.ZT==''?'':colorArr[Number(item1.ZT)]}">{{item1.ZTMC}}</div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="carousel-item1" v-for="(item1,index1) in item.data4" :key="index1">
                                        <div class="carousel-item1-mc" :title="item1.JCDXMC">{{item1.JCDXMC}}</div>
                                        <div class="carousel-item1-zt" :style="{color:item1.ZT==''?'':colorArr[Number(item1.ZT)]}">{{item1.ZTMC}}</div>
                                    </div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="content-border" v-else>
            <div class="no-data">暂无评估数据</div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import * as handleDateFormat from './handleDateFormat.js'
export default {
    name: "assess_data",
    data() {
        return {
            dateValue: null,
            pgData: [], //评估数据
            timeAxisList: [],
            pgdxProps: {
                checkStrictly: true,
                children: 'JCDXLIST',
                label: 'MC',
                value: 'MC'
            },
            pgdxOptions: [],
            pgdxValue: '',
            pgdxObj: null,
            carouselDatas: [],
            pages: 0,
            colorArr: ['#0099ff', '#eecb5f', '#f59a23', '#d9001b', 'green', 'orange', 'pink'],
            currentAxisID: '',
            axisPages: 0,
            axisCurrentPage: 0,
            timeAxisPageSize: 4,
            timeAxisChildPageSize: 24,
            currentItem: {},
            isResouceShow: 0,
            loading: null,
        }
    },
    props: {
        selectData: {
            type: Object,
            default: () => { }
        },
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    components: {
    },
    watch: {
        selectData() {
            this.dateValue = null;
            if (this.selectData) {
                this.handleSelectData();
            }
        },
        // eslint-disable-next-line no-unused-vars
        'dataSource.pgOption': function(val) {
            this.pgdxValue = '';
            if (this.dataSource.pgOption) {
                this.handleDataSource();
            }
        },
    },
    mounted() {

        if (this.selectData) {
            this.handleSelectData();
        }
        if (this.dataSource.pgOption) {
            this.handleDataSource();
        }
    },
    methods: {
        ...mapActions([
            'GetPgsjInfo', //获取评估数据信息
        ]),
        openFullScreen() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        closeFullScreen() {
            if(this.loading) this.loading.close();
        },
        // 初始化时间范围
        handleSelectData() {
            this.dateValue = null;
            if (this.selectData) {
                let startDate = this.selectData.RWKSSJ || '';
                let endDate = this.selectData.RWJSSJ || '';
                this.dateValue = [new Date(startDate), new Date(endDate)];
            }
        },
        // 初始化评估对象选择值
        handleDataSource() {
            this.pgdxObj = null;
            this.pgdxValue = '';
            this.pgData = [];
            this.pgdxOptions = [];
            if (this.dataSource.pgOption) {
                ++this.isResouceShow;
                this.pgdxValue = this.dataSource.pgOption.MC;
                this.pgdxOptions.push(this.dataSource.pgOption);
                this.handlePgdxChanged([this.dataSource.pgOption.MC]);
            }
        },
        handlePgdxChanged(val) {
            let valStr = '';
            this.pgdxObj = null;
            if (val && val.length > 0) {
                valStr = val[val.length - 1];
                if (this.pgdxOptions && this.pgdxOptions.length > 0) {
                    this.pgdxOptions.forEach(item => {
                        if (item.MC === valStr) {
                            this.pgdxObj = item;
                            this.pgdxValue = item.MC;
                        } else {
                            if (item.JCDXLIST && item.JCDXLIST.length > 0) {
                                item.JCDXLIST.forEach(item1 => {
                                    if (item1.MC === valStr) {
                                        this.pgdxObj = item1;
                                        this.pgdxValue = item1.MC;
                                    }
                                })
                            }
                        }
                    })
                    if (this.pgdxObj && this.dateValue && this.dateValue.length > 0 && this.selectData) {
                        this.getPgsjInfo();
                    }
                }
            }
        },
        // 选择时间范围
        handleDateChanged(val) {
            let num = 1;
            if (this.selectData.ZQDW === '0') {
                // 年
                num = Number(this.selectData.ZQ) * 12;
            } else if (this.selectData.ZQDW === '1') {
                // 月
                num = Number(this.selectData.ZQ);
            }
            let recentZqDate = new Date(val[1].getTime() - 24 * 60 * 60 * 1000 * 30 * num);
            if (val[0].getTime() < recentZqDate.getTime()) {
                this.dateValue[0] = recentZqDate;
            }
            this.getPgsjInfo();
        },
        // 获取评估数据信息
        async getPgsjInfo() {
            this.pgData = [];
            this.timeAxisList = [];
            this.carouselDatas = [];
            this.pages = 0;

            if (this.pgdxObj && this.dateValue && this.dateValue.length > 0) {
                if (!this.dateValue[0] || !this.dateValue[1]) return;
                let startDate = handleDateFormat.handleDate(this.dateValue[0]);
                let endDate = handleDateFormat.handleDate(this.dateValue[1]);
                this.openFullScreen();
                let result = await this.GetPgsjInfo({
                    Pgpzid: this.selectData.PGPZID, //String 评估配置ID
                    Jcdxid: this.pgdxObj.JCDXID ? this.pgdxObj.JCDXID : this.pgdxObj.YCYSBM, //String 监测对象ID
                    IsChild: this.pgdxObj.IsChild,
                    StartTime: startDate, //String 开始时间
                    EndTime: endDate, //String 结束时间
                })
                this.closeFullScreen();
                if (result && result.length > 0) {
                    this.pgData = result;
                    this.axisCurrentPage = 0;
                    this.axisPages = Math.ceil(this.pgData.length / this.timeAxisPageSize);

                    this.handleArrowData();
                }
            }
        },
        handleArrowTopClick() {
            if (this.axisPages == 1) return;
            if (this.axisCurrentPage === 0) {
                this.axisCurrentPage = this.axisPages - 1;
            } else {
                this.axisCurrentPage--;
            }
            this.handleArrowData();
        },
        handleArrowBottomClick() {
            if (this.axisPages == 1) return;
            if (this.axisCurrentPage === this.axisPages - 1) {
                this.axisCurrentPage = 0;
            } else {
                this.axisCurrentPage++;
            }
            this.handleArrowData();
        },
        handleArrowData() {
            this.timeAxisList = [];
            for (let i = 0; i < this.pgData.length; i++) {
                if (i >= this.axisCurrentPage * this.timeAxisPageSize && i < (this.axisCurrentPage + 1) * this.timeAxisPageSize) {
                    this.timeAxisList.push(this.pgData[i]);
                }
            }
            if (this.timeAxisList && this.timeAxisList.length > 0) {
                if (this.timeAxisList[0].PGMXLIST && this.timeAxisList[0].PGMXLIST.length > 0) {
                    this.pages = Math.ceil(this.timeAxisList[0].PGMXLIST.length / this.timeAxisChildPageSize);
                }
                this.handleTimeAxisClick(this.timeAxisList[0]);
            } else {
                this.carouselDatas = [];
            }
        },
        handleTimeAxisClick(val) {
            this.carouselDatas = [];
            this.currentAxisID = val.PGDXID + val.CJSJ;
            this.currentItem = val;
            if (val.PGMXLIST && val.PGMXLIST.length > 0) {
                if (val.PGMXLIST && val.PGMXLIST.length > 0) {
                    this.pages = Math.ceil(val.PGMXLIST.length / this.timeAxisChildPageSize);
                }
                this.handlePgsjInfo(val.PGMXLIST);
            }
        },
        handlePgsjInfo(arr) {
            for (let i = 0; i < this.pages; i++) {
                this.carouselDatas.push({
                    data1: [],
                    data2: [],
                    data3: [],
                    data4: [],
                })
                for (let j = 0; j < arr.length; j++) {
                    if (j < this.timeAxisChildPageSize * (i + 1) - 18) {
                        this.carouselDatas[i].data1.push(arr[j]);
                    } else if (j < this.timeAxisChildPageSize * (i + 1) - 12 && j >= this.timeAxisChildPageSize * (i + 1) - 18) {
                        this.carouselDatas[i].data2.push(arr[j]);
                    } else if (j < this.timeAxisChildPageSize * (i + 1) - 6 && j >= this.timeAxisChildPageSize * (i + 1) - 12) {
                        this.carouselDatas[i].data3.push(arr[j]);
                    } else if (j < this.timeAxisChildPageSize * (i + 1) && j >= this.timeAxisChildPageSize * (i + 1) - 6) {
                        this.carouselDatas[i].data4.push(arr[j]);
                    }
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.monitor-data-base {
    width: 100%;
    .select-bar {
        height: 40px;
        line-height: 40px;
        background: #fff;
        .select-label {
            padding: 0 20px;
            color: #23468c;
        }
        .assess-bh-select {
            width: 200px;
        }
    }
    .content-border {
        position: relative;
        width: 100%;
        margin-top: 10px;
        // background: #fff;
        
        .arrow-top {
            position: absolute;
            top: 0;
            left: 100px;
            cursor: pointer;
        }
        .arrow-bottom {
            position: absolute;
            bottom: 0;
            left: 100px;
            cursor: pointer;
        }
        .arrow-top>i,
        .arrow-bottom>i {
            font-size: 20px;
        }
        .time-axis {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 190px;
            height: 100%;
            background: #fff;
            .time-item-line {
                position: absolute;
                top: 0;
                left: 54px;
                width: 3px;
                height: 100%;
                background: #eaece6;
            }
            .time-item {
                height: 40px;
                margin-top: 40px;
                padding-left: 45px;
                display: flex;
                flex-flow: row nowrap;
                .time-item-icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    border: 1px solid #f2f2f2;
                    border-radius: 50%;
                    cursor: pointer;
                    .time-item-icon-in {
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        margin-left: 50%;
                        margin-top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .time-label {
                    cursor: pointer;
                }
            }
        }
        .content {
            width: 100%;
            height: 100%;
            padding: 10px 0 20px 210px;
            .step2-header {
                position: relative;
                height: 44px;
                line-height: 44px;
                padding: 0 20px;
                border-bottom: 1px solid #85b2cc;
                .step2-title {
                    color: #7f7f7f;
                }
                .step2-title-right {
                    position: absolute;
                    right: 70px;
                    bottom: 0;
                    color: #555;
                }
            }
            .top-content {
                position: relative;
                width: 100%;
                padding: 10px 0px 0px;
                .carousel-content {
                    width: 100%;
                    padding: 10px 20px;
                    overflow: hidden;
                    .carousel-item {
                        float: left;
                        width: calc(25% - 10px);
                        margin-left: 10px;
                        .carousel-item1 {
                            position: relative;
                            height: 40px;
                            line-height: 40px;
                            padding: 0 10px;
                            .carousel-item1-mc {
                                width: calc(100% - 70px);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .carousel-item1-zt {
                                position: absolute;
                                top: 0;
                                right: 10px;
                                width: 60px;
                            }
                        }
                        .carousel-item1:nth-of-type(odd) {
                            background: #f7f9f4;
                        }
                        .carousel-item1:nth-of-type(even) {
                            background: #eaece6;
                        }
                    }
                    .carousel-item:first-child {
                        margin-left: 5px;
                    }
                }
                .top-table {
                    width: calc(50% - 10px);
                }
                .top-table2 {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                }
            }
        }
        .no-data {
            height: 100px;
            line-height: 100px;
            text-align: center;
        }
    }
}
</style>
