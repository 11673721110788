<template>
    <div class="monitor-data-base">
        <div class="select-bar">
            <label class="select-label">本体病害</label>
            <el-select class="assess-bh-select" v-model="bhValue" placeholder="请选择本体病害" filterable @change="handleBhChanged">
                <el-option v-for="(item,index) in dataSource.bhOption" :key="index" :label="item.JCDXMC" :value="item.JCDXID">
                </el-option>
            </el-select>
            <el-date-picker class="pg-date-picker" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" size="mini" @change="handleDateChanged">
            </el-date-picker>
        </div>
        <div class="chart-border">
            <div class="chart-left">
                <div class="jcd-title">本体病害监测点位</div>
                <div class="left-top scrollbar">
                    <!-- <div class="jcd-list" v-for="(item,index) in jcdList" :key="index" @click="handleJcdClick(item)">
                        <div class="jcd-item" :style="{background: jcdValue===item.ID?'#e2e8db':''}" :title="item.JCWZMC">{{item.JCWZMC}}</div>
                    </div> -->
                    <div v-if="tpDwList.length===0" class="no-jcdw">暂无监测点位</div>
                    <div v-else class="jcd-list" v-for="(item,index) in tpDwList" :key="item.ID" @click="handleTpDwClick(item)">
                        <div class="jcd-item" :style="{background: tpDwValue===item.ID?'#e2e8db':''}" :title="item.JCWZMC">{{item.JCWZMC}}</div>
                    </div>
                </div>
                <!-- <div class="left-bottom">
                    <div class="type-list" v-for="(item,index) in typeList" :key="index" @click="handleTypeClick(item)">
                        <div class="type-big-cicle">
                            <div class="type-small-cicle" v-show="item.value === typeValue"></div>
                        </div>
                        <div class="type-item" :title="item.label">{{item.label}}</div>
                    </div>
                </div> -->
            </div>
            <div class="chart-right">
                <div v-show="noData" class="no-data">暂无监测数据</div>
                <!-- <div class="chart-content" :id="`chartId-${chartId}`"></div> -->
                <div v-show="!noData" class="chart-content">
                    <el-carousel :interval="4000" type="card" height="320px" v-viewer>
                        <el-carousel-item v-for="(item12, index12) in jcsjDataByPic" :key="index12">
                        <!-- <el-carousel-item v-for="(item12, index12) in carouselList" :key="index12"> -->
                            <el-image :src="item12.TPLJ" fit="cover" class="imgObj pointer">
                                <div slot="placeholder" class="imgObj">
                                    加载中
                                    <span class="dot">...</span>
                                </div>
                                <div slot="error" style="width:100%;height:100%;">
                                    <el-image
                                        fit="cover"
                                        :src="noImg"
                                        style="width:100%;height:100%;"
                                    ></el-image>
                                </div>
                            </el-image>
                            <div class="image-cjsj">{{ item12.CJSJ }}</div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import * as handleChartArray from './handleChartArray.js'
import * as handleDateFormat from './handleDateFormat.js'
export default {
    name: "monitor_data",
    data() {
        return {
            bhValue: '',
            tpDwList: [], // 图片点位列表
            typeList: [
                {
                    value: '0',
                    label: '日平均值'
                },
                {
                    value: '1',
                    label: '周均值、最大、最小值'
                },
                {
                    value: '2',
                    label: '月度均值、最大、最小值'
                }
            ],
            tpDwValue: '',
            typeValue: '0',
            dateValue: null,
            chartId: Math.random() * 100,
            myChart: null,
            jcsjDataByPic: [],
            carouselList: [
                {
                    CJSJ: '2021-04-19 11:40:23',
                    TPLJ: 'https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png'
                },{
                    CJSJ: '2021-04-19 11:40:23',
                    TPLJ: 'https://t11.baidu.com/it/u1=1596281125&u2=4166942531&fm=76'
                },{
                    CJSJ: '2021-04-19 11:40:23',
                    TPLJ: 'https://t11.baidu.com/it/u1=1367819683&u2=3828355124&fm=76'
                },{
                    CJSJ: '2021-04-19 11:40:23',
                    TPLJ: 'https://dss2.bdstatic.com/5bVYsj_p_tVS5dKfpU_Y_D3/res/r/image/2021-4-19/%E9%BC%A0%E6%A0%87.jpg'
                },{
                    CJSJ: '2021-04-19 11:40:23',
                    TPLJ: 'https://dss2.bdstatic.com/5bVYsj_p_tVS5dKfpU_Y_D3/res/r/image/2021-4-19/%E8%9E%BA%E8%9B%B3%E7%B2%89.jpg'
                }
            ],
            noImg: require("@image/bigScreen/noImg.png"),
            loading: null,
            noData: false,
        }
    },
    props: {
        selectData: {
            type: Object,
            default: () => { }
        },
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    watch:{
        selectData(){
            this.dateValue = null;
            if(this.selectData){
                this.handleSelectData();
            }
        },
        // eslint-disable-next-line no-unused-vars
        'dataSource.bhOption':function(val){
            this.bhValue = '';
            if(this.dataSource.bhOption && this.dataSource.bhOption.length>0){
                this.handleDataSource();
            }
        },
    },
    mounted() {
        if(this.selectData){
            this.handleSelectData();
        }
        if(this.dataSource.bhOption && this.dataSource.bhOption.length>0){
            this.handleDataSource();
        }
        // this.initChart();

        window.addEventListener('resize', () => {
            // const myChart = this.$echart.init(document.getElementById('chart-left'))
            this.myChart.resize()
        })
    },
    methods: {
        ...mapActions([
            'GetJcsjInfo', //获取监测数据信息
            'GetJcsjDataByPic', // 查询监测数据图片数据信息
        ]),
        openFullScreen() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        closeFullScreen() {
            if(this.loading) this.loading.close();
        },
        // 初始化时间范围
        handleSelectData(){
            this.dateValue = null;
            if(this.selectData){
                let startDate = this.selectData.RWKSSJ || '';
                let endDate = this.selectData.RWJSSJ || '';
                this.dateValue = [new Date(startDate),new Date(endDate)];
            }
        },
        // 初始化病害选择值
        handleDataSource(){
            this.bhValue = '';
            this.tpDwList = [];
            this.tpDwValue = '';
            if(this.dataSource.bhOption && this.dataSource.bhOption.length>0){
                this.bhValue = this.dataSource.bhOption[0].JCDXID;
                this.tpDwList = this.dataSource.bhOption[0].TPDWLIST;
                if(this.tpDwList && this.tpDwList.length>0){
                    this.tpDwValue = this.tpDwList[0].ID;
                }
                if(this.bhValue && this.dateValue && this.dateValue.length>0 && this.selectData){
                    this.getJcsjDataByPic();
                }
            }
        },
        // 选择时间范围
        handleDateChanged(val){
            let num = 1;
            if(this.selectData.ZQDW === '0'){
                // 年
                num = Number(this.selectData.ZQ) * 12;
            }else if(this.selectData.ZQDW === '1'){
                // 月
                num = Number(this.selectData.ZQ);
            }
            let recentZqDate = new Date(val[1].getTime() - 24 * 60 * 60 * 1000 * 30 * num);
            if(val[0].getTime() < recentZqDate.getTime()){
                this.dateValue[0] = recentZqDate;
            }
            this.getJcsjDataByPic();
        },
        // 选择病害
        handleBhChanged(val) {
            let _this = this;
            this.tpDwList = [];
            this.tpDwValue = '';
            if (this.dataSource.bhOption && this.dataSource.bhOption.length > 0) {
                this.dataSource.bhOption.forEach(item => {
                    if (item.JCDXID === val) {
                        _this.tpDwList = item.TPDWLIST;
                        if(_this.tpDwList && _this.tpDwList.length>0){
                            this.tpDwValue = _this.tpDwList[0].ID;
                        }
                    }
                })
                this.getJcsjDataByPic();
            }
        },
        // 选择监测点
        handleJcdClick(val){
            this.jcdValue = val.ID;
            this.getJcsjInfo();
        },
        // 选择计算类型
        handleTypeClick(val){
            this.typeValue = val.value;
            this.getJcsjInfo();
        },
        // 获取监测数据信息
        async getJcsjInfo(){
            if(this.myChart) this.myChart.clear();
            if(this.bhValue!=='' && this.jcdValue!=='' && this.typeValue!=='' && this.dateValue && this.dateValue.length>0){
                if (!this.dateValue[0] || !this.dateValue[1]) return;
                let startDate = handleDateFormat.handleDate(this.dateValue[0]);
                let endDate = handleDateFormat.handleDate(this.dateValue[1]);
                let result = await this.GetJcsjInfo({
                    Jcdxid: this.bhValue, //String 监测对象ID
                    Jcwzid: this.jcdValue, //string 监测位置ID
                    Lx: this.typeValue, //String 类型【0：按天取均值；1：按周取均值；2：按月取均值；3：获取原始数据】
                    StartTime: startDate, //String 开始时间
                    EndTime: endDate, //String 结束时间
                })
                let prepareArr = [];
                let legendData = [];
                let dwData = [];
                if(result && result.length>0 && result[0].JCSJLIST && result[0].JCSJLIST.length>0){
                    result[0].JCSJLIST.forEach(item1=>{
                        if(item1 && item1.JCSJLIST && item1.JCSJLIST.length>0){
                            legendData.push(item1.JCX);
                            item1.JCSJLIST[0].DW?dwData.push(item1.JCSJLIST[0].DW):dwData.push('');
                            item1.JCSJLIST.forEach(item2=>{
                                prepareArr.push(item2.CJSJ);
                            })
                        }
                    })
                    let xAxisData = handleChartArray.handleXAxisDataFun(prepareArr);
                    let fieldName = {
                        CHILD: 'JCSJLIST',
                        NAME: 'JCX',
                        XVALUE: 'CJSJ',
                        YVALUE: 'JCZ'
                    };
                    let yAxisData = handleChartArray.handleYAxisDataFun(xAxisData,result[0].JCSJLIST,fieldName);
                    this.setOption(legendData,dwData,xAxisData,yAxisData);
                }else{
                    this.setOption([],[],[],[]);
                }
            }
        },
        initChart(){
            // 基于准备好的dom，初始化echarts实例
            this.myChart = this.$echarts.init(document.getElementById(`chartId-${this.chartId}`));
        },
        // 初始化图表
        setOption(legendData,dwData,xAxisData,yAxisData){
            if(this.myChart) this.myChart.clear();
            if(!legendData || legendData.length===0 || !dwData || dwData.length===0 || !xAxisData || xAxisData.length===0 || !yAxisData || yAxisData.length===0) return;
            // 指定图表的配置项和数据
            var option = {
                tooltip: {
                    trigger: 'axis',
                    // position: function (pt) {
                    //     return [pt[0], '10%'];
                    // },
                    axisPointer: {
                        type: 'cross'
                    },
                    // eslint-disable-next-line no-unused-vars
                    formatter: function(params, ticket, callback) {
                        let tooltipDataArr = []
                        if(params && params.length>0){
                            params.forEach(item1=>{
                                // if(item1.value){
                                    tooltipDataArr.push({
                                        color: item1.color,
                                        marker: item1.marker,
                                        seriesName: item1.seriesName,
                                        value: item1.value || '-',
                                        dw: item1.value?dwData[item1.seriesIndex]:''
                                    })
                                // }
                            })
                        }
                        let tooltipData = params[0].name;
                        if(tooltipDataArr && tooltipDataArr.length>0){
                            tooltipDataArr.forEach(item2=>{
                                tooltipData = tooltipData + '<br/>' + item2.marker + item2.seriesName + '：' + item2.value + item2.dw
                            })
                        }
                        return tooltipData;
                    }
                },
                grid: {
                    top: 60,
                    right: 60,
                    bottom: 70,
                    left: 60,
                },
                legend: {
                    top: 20,
                    data: legendData
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '100%']
                },
                dataZoom: [{
                    type: 'inside',
                    start: 50,
                    end: 100
                }, {
                    // start: 0,
                    // end: 10,
                    // handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    // handleSize: '80%',
                    // handleStyle: {
                    //     color: '#fff',
                    //     shadowBlur: 3,
                    //     shadowColor: 'rgba(0, 0, 0, 0.6)',
                    //     shadowOffsetX: 2,
                    //     shadowOffsetY: 2
                    // }
                }],
                series: yAxisData,
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
        },

        // 选择图片点位
        handleTpDwClick(val){
            this.tpDwValue = val.ID;
            this.getJcsjDataByPic();
        },
        // 查询监测数据图片数据信息
        async getJcsjDataByPic(){
            // if(this.myChart) this.myChart.clear();
            this.jcsjDataByPic = [];
            this.noData = true;
            if(this.bhValue!=='' && this.tpDwValue!=='' && this.dateValue && this.dateValue.length>0){
                if (!this.dateValue[0] || !this.dateValue[1]) return;
                let startDate = handleDateFormat.handleDate(this.dateValue[0]);
                let endDate = handleDateFormat.handleDate(this.dateValue[1]);
                let result = await this.GetJcsjDataByPic({
                    Jcdxid: this.bhValue, //String 监测对象ID
                    Jcwzid: this.tpDwValue, //string 监测位置ID
                    StartTime: startDate, //String 开始时间
                    EndTime: endDate, //String 结束时间
                })
                if(result && result.length>0){
                    this.noData = false;
                    this.jcsjDataByPic = result;
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.monitor-data-base {
    width: 100%;
    .select-bar {
        height: 40px;
        line-height: 40px;
        background: #fff;
        .select-label {
            padding: 0 20px;
            color: #23468c;
        }
        .assess-bh-select {
            width: 200px;
        }
    }
    .chart-border {
        position: relative;
        width: 100%;
        height: 340px;
        margin-top: 10px;
        // background: #fff;
        .chart-left {
            width: 230px;
            height: 100%;
            margin-right: 20px;
            background: #fff;
            .jcd-title {
                height: 30px;
                line-height: 30px;
                margin-top: 10px;
                padding-left: 20px;
                // border-bottom: 1px solid #ccc;
                color: #4d6093;
            }
            .no-jcdw {
                margin-top: 5px;
                margin-left: 10px;
                font-size: 12px;
            }
            .left-top {
                width: 100%;
                height: calc(100% - 35px);
                // padding: 0 0 10px;
                overflow-x: hidden;
                overflow-y: auto;
                margin-top: 5px;
                .jcd-list0 {
                    padding: 0 0 0 10px;
                    .jcd-item0 {
                        font-size: 12px;
                        color: #909399;
                    }
                    
                }
                .jcd-list {
                    height: 28px;
                    line-height: 28px;
                    cursor: pointer;
                }
            }
            .left-bottom {
                width: 100%;
                height: 90px;
                padding: 10px 0 0 0;
                .type-list {
                    display: flex;
                    flex-flow: row nowrap;
                    height: 26px;
                    line-height: 26px;
                    cursor: pointer;
                    .type-big-cicle {
                            width: 14px;
                            height: 14px;
                            margin-top: 6px;
                            margin-right: 6px;
                            line-height: 12px;
                            border: 1px solid #999;
                            border-radius: 50%;
                        .type-small-cicle {
                            width: 6px;
                            height: 6px;
                            margin-top: 50%;
                            margin-left: 50%;
                            transform: translate(-50%, -50%);
                            border-radius: 50%;
                            background: #469bea;
                        }
                    }
                }
            }
            .jcd-item{
                padding: 0 20px 0 30px;
                color: #23468c;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .type-item {
                color:#23468c;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .chart-right {
            position: absolute;
            right: 0px;
            top: 0px;
            width: calc(100% - 250px);
            height: 100%;
            .no-data {
                width: 100%;
                height: 100%;
                line-height: 330px;
                text-align: center;
            }
            .chart-content{
                width: 100%;
                height: 100%;
                .el-image {
                    width: 100%;
                    height: calc(100% - 30px);
                }
                .image-cjsj {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 10px;
                }
            }
        }
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }
    
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    
    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }
}
</style>
